import { gql } from '@apollo/client'
import { snakeCase, startCase } from 'lodash'
import useQuery from 'hooks/useQuery'
import usePermissions, { permissions } from 'hooks/usePermissions'

export const LicenseTransaction = {
  fragments: {
    details: gql`
      fragment LicenseTransaction_details on LicenseTransaction {
        id
        available
        category
        used
        totalLicenses
        solution {
          id
          type
          usageAllowance
        }
      }
    `
  }
}

export const License = {
  fragments: {
    details: gql`
      fragment License_details on License {
        id
        licenseTransaction {
          ...LicenseTransaction_details
        }
        account {
          id
          role {
            name
          }
        }
      }

      ${LicenseTransaction.fragments.details}
    `
  }
}

export const LICENSE_TRANSACTIONS_QUERY = gql`
  query LicenseTransactionsQuery {
    licenseTransactions {
      ...LicenseTransaction_details
    }
  }

  ${LicenseTransaction.fragments.details}
`

export const ENQUIRE_SOLUTION_MUTATION = gql`
  mutation EnquireSolutionMutation($input: EnquireSolutionInput!) {
    enquireSolution(input: $input) {
      id
    }
  }
`

export const REQUEST_LICENSE_MUTATION = gql`
  mutation RequestLicenseMutation($input: RequestLicenseInput!) {
    requestLicense(input: $input) {
      id
    }
  }
`

export const LICENSE_TRANSACTION_CATEGORIES_QUERY = gql`
  query LicenseTransactionCategoriesQuery {
    licenseTransactionCategories {
      categories
    }
  }
`

export const CREATE_LICENSE_MUTATION = gql`
  mutation createLicenseMutation($input: CreateLicenseInput!) {
    createLicense(input: $input) {
      ...License_details
    }
  }

  ${License.fragments.details}
`

export const EXPIRE_LICENSE_MUTATION = gql`
  mutation ExpireLicenseMutation($id: ID!) {
    expireLicense(id: $id) {
      id
    }
  }
`

const sortSolutionsByType = solutionsList => {
  const solutionsSortOrder = [
    'OnCall',
    'OnCallCalendar',
    'Enterprise',
    'OpNote',
    'SurgeryWorkflow',
    'IntegrationHub',
    'Voice'
  ]

  return [...solutionsList].sort(
    (a, b) =>
      solutionsSortOrder.indexOf(a?.type) - solutionsSortOrder.indexOf(b?.type)
  )
}

const sortLicenseByCategory = licenseCategory => {
  const sortOrder = ['provider', 'staff', 'facility']

  licenseCategory.sort(
    (a, b) => sortOrder.indexOf(a.category) - sortOrder.indexOf(b.category)
  )

  return licenseCategory
}

const formatLicenseTransactionBySolution = licenseTypeData => {
  const sortedData = sortLicenseByCategory(licenseTypeData)

  return sortedData.map(item => ({
    id: item.id,
    category: item.category,
    used: item.used,
    available: item.available,
    total: item.totalLicenses,
    ...(item.solution.type === 'AINote'
      ? { usageAllowance: item.solution.usageAllowance }
      : {})
  }))
}

export const planOptions = ['1000', '5000', '10000', '15000']

export const formatSolutionsListforAINote = (sortedSolutions, licenses) => {
  const aiNoteSolutions = sortedSolutions.filter(
    solution => solution.title === 'AI Note'
  )

  const aiNoteLicense = licenses?.find(
    license =>
      license.licenseTransaction?.solution?.type === 'AINote' ||
      license.solution?.type === 'AINote'
  )

  const combinedDataArray = aiNoteSolutions.flatMap(obj => obj.data)

  const existingPlans = combinedDataArray.map(data => data.usageAllowance)
  planOptions.forEach(plan => {
    if (!existingPlans.includes(plan)) {
      combinedDataArray.push({
        available: 0,
        category: 'provider',
        id: `${combinedDataArray.length + 1}`,
        usageAllowance: plan,
        total: 0,
        used: 0
      })
    }
  })

  const combinedAINoteObject = {
    solutionId: !licenses?.length
      ? '8'
      : aiNoteLicense?.licenseTransaction?.solution?.id ||
        aiNoteLicense?.solution?.id,
    icon: 'ai_note',
    title: 'AI Note',
    data: aiNoteLicense ? sortLicenseByCategory(combinedDataArray) : []
  }

  const combinedSolutions = [
    ...sortedSolutions.filter(obj => obj.title !== 'AI Note'),
    combinedAINoteObject
  ]

  return combinedSolutions
}

const formatSolutionsList = ({
  sortedSolutions,
  licensesOrTransactions,
  isUserForm
}) => {
  const aiNoteLicense = licensesOrTransactions?.find(
    license =>
      license.licenseTransaction?.solution?.type === 'AINote' ||
      license.solution?.type === 'AINote'
  )

  const aiNoteSolutions = sortedSolutions.filter(
    solution => solution.title === 'AI Note'
  )

  const combinedDataArray = aiNoteSolutions.flatMap(obj => obj.data)

  const existingPlans = combinedDataArray.map(data => data.usageAllowance)
  planOptions.forEach(plan => {
    if (!existingPlans.includes(plan)) {
      combinedDataArray.push({
        available: 0,
        category: 'provider',
        id: null,
        usageAllowance: plan,
        total: 0,
        used: 0
      })
    }
  })

  const combinedAINoteObject = {
    solutionId: aiNoteLicense
      ? aiNoteLicense?.licenseTransaction?.solution?.id ||
        aiNoteLicense?.solution?.id
      : aiNoteSolutions[0]?.solutionId,
    icon: 'ai_note',
    title: 'AI Note',
    // eslint-disable-next-line no-nested-ternary
    data: isUserForm
      ? sortLicenseByCategory(combinedDataArray) || []
      : aiNoteLicense
      ? sortLicenseByCategory(combinedDataArray)
      : []
  }

  return [
    ...sortedSolutions.filter(obj => obj.title !== 'AI Note'),
    combinedAINoteObject
  ]
}

export const formatSolutionsListForUserForm = (sortedSolutions, licenses) =>
  formatSolutionsList({
    sortedSolutions,
    licensesOrTransactions: licenses,
    isUserForm: true
  })

export const formatSolutionsListForLicensingPage = (
  sortedSolutions,
  licenseTransactions
) =>
  formatSolutionsList({
    sortedSolutions,
    licensesOrTransactions: licenseTransactions,
    isUserForm: false
  })

export const formatLicenseTransactionsData = (
  licenseTransactionsData,
  solutionList,
  userLicenses
) => {
  const formattedSolutions = sortSolutionsByType(solutionList).map(
    solution => ({
      solutionId: solution.id,
      icon: snakeCase(solution.type),
      title: startCase(solution.type),
      data: formatLicenseTransactionBySolution(
        licenseTransactionsData.filter(
          licenseTransaction => licenseTransaction?.solution?.id === solution.id
        )
      )
    })
  )

  return userLicenses
    ? formatSolutionsListForUserForm(formattedSolutions, userLicenses)
    : formatSolutionsListForLicensingPage(
        formattedSolutions,
        licenseTransactionsData
      )
}

function useLicenseTransactionCategories() {
  const canViewLicenseTransactionCategories = usePermissions(
    permissions.VIEW_LICENSE_TRANSACTION_CATEGORIES
  )

  const { data: { licenseTransactionCategories = {} } = {} } = useQuery(
    LICENSE_TRANSACTION_CATEGORIES_QUERY,
    {
      skip: !canViewLicenseTransactionCategories
    }
  )

  return licenseTransactionCategories.categories
}

export function useSelectLicenseTransactionCategories(solution) {
  let categories = useLicenseTransactionCategories()

  if (categories) {
    // eslint-disable-next-line no-unused-expressions
    solution === 'On Call Calendar'
      ? (categories = categories.filter(
          category => category.value === 'facility'
        ))
      : (categories = categories.filter(
          category => category.value !== 'facility'
        ))
  }

  return [categories]
}

export function sanitizeLicenseDataForUserForm(
  licenseTransactions,
  solutions,
  userLicenses
) {
  const formattedLicenseTransactionsData = formatLicenseTransactionsData(
    licenseTransactions,
    solutions,
    userLicenses
  ).filter(lt => lt.data.length)
  let sanitizedLicenseTransactions = formattedLicenseTransactionsData.map(
    licenseTransaction => {
      if (licenseTransaction.title === 'AI Note') {
        return {
          ...licenseTransaction,
          data: licenseTransaction.data.reduce((acc, licenseType) => {
            acc[licenseType.category] =
              (acc[licenseType.category] || 0) + (licenseType.available || 0)
            return acc
          }, {})
        }
      }
      return {
        ...licenseTransaction,
        data: licenseTransaction.data.reduce(
          (acc, licenseType) => ({
            ...acc,
            [licenseType.category]: licenseType.available || 0
          }),
          {}
        )
      }
    }
  )

  if (!userLicenses) return sanitizedLicenseTransactions

  const sanitizedLicenses = userLicenses.map(license => ({
    ...license,
    solutionId: license.licenseTransaction.solution.id
  }))
  sanitizedLicenseTransactions = sanitizedLicenseTransactions.map(
    licenseTransaction => {
      const { solutionId: ltSolutionId } = licenseTransaction
      const license = sanitizedLicenses.find(
        ({ solutionId }) => solutionId === ltSolutionId
      )

      if (license) {
        return {
          ...licenseTransaction,
          license
        }
      }

      return licenseTransaction
    }
  )

  return sanitizedLicenseTransactions
}

export default {
  LICENSE_TRANSACTIONS_QUERY,
  REQUEST_LICENSE_MUTATION,
  CREATE_LICENSE_MUTATION,
  EXPIRE_LICENSE_MUTATION,
  formatLicenseTransactionsData,
  useSelectLicenseTransactionCategories
}
