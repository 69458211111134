import { gql } from '@apollo/client'
import { useState } from 'react'
import { startCase } from 'lodash'

import useAppContext from 'hooks/useAppContext'

const AVAILABLE_TYPES = Object.freeze({
  enterprise: 'Enterprise',
  'integration-hub': 'IntegrationHub',
  'surgery-center': 'SurgeryCenter',
  'surgery-clinic': 'SurgeryClinic',
  'on-call-calendar': 'OnCallCalendar',
  'on-call': 'OnCall',
  'op-note': 'OpNote',
  voice: 'Voice',
  'portal-dictation': 'PortalDictation'
})

export const SOLUTIONS_QUERY = gql`
  query SolutionsQuery {
    solutions {
      id
      type
      usageAllowance
    }
  }
`

export const useSelectSolution = () => {
  const { solutions } = useAppContext()

  const [selectedSolution, setSelectedSolution] = useState(null)

  const solutionOptions = solutions.map(solution => ({
    label: startCase(solution.type),
    value: solution.id
  }))

  return [solutionOptions, selectedSolution, setSelectedSolution]
}

export default {
  SOLUTIONS_QUERY,
  useSelectSolution,
  AVAILABLE_TYPES
}
