import { gql } from '@apollo/client'

import useFeature, { featureTypes } from 'hooks/useFeature'
import usePermissions, { permissions } from 'hooks/usePermissions'
import useQuery from 'hooks/useQuery'
import { filterKeys, useFilterState } from 'contexts/FilterContext'

const MAX_ORGANIZATIONS = 100

export const ORGANIZATIONS_LIST_QUERY = gql`
  query OrganizationsListQuery(
    $first: Int
    $skip: Int
    $sort: String
    $sortDirection: String
  ) {
    organizations(
      first: $first
      skip: $skip
      sort: $sort
      sortDirection: $sortDirection
    ) {
      id
      name
    }
  }
`

class Organization {
  static getLogin(account) {
    if (!account) {
      return null
    }

    const {
      email,
      externalLogin,
      loginType = Organization.loginTypes.INTERNAL
    } = account || {}

    switch (loginType) {
      case Organization.loginTypes.EXTERNAL || Organization.loginTypes.SAML:
        return externalLogin || email
      case Organization.loginTypes.INTERNAL:
        return email
      case Organization.loginTypes.OAUTH:
        return null
      default:
        throw new Error('Invalid loginType passed to `getLogin`.')
    }
  }

  static isEmr(emr) {
    const emrs = ['aprima', 'allscripts', 'epic-systems', 'modmed']
    const isEmr = emrs.includes(emr)

    return isEmr
  }
}

Organization.fragments = {
  details: gql`
    fragment Organization_details on Organization {
      id
      dataSources {
        id
        kind
        mode
        organizationId
      }
      externalSource
      externalSourceSettings
      linkedOrganizations
      name
      displayName
      image
      shareToken
      idleTimeout
      nuanceToken

      surgeryRequestRequiredFields {
        id
        name
        description
        required
      }
    }
  `
}

Organization.loginTypes = Object.freeze({
  EXTERNAL: 'external',
  INTERNAL: 'internal',
  OAUTH: 'oauth2',
  SAML: 'saml'
})

Organization.solutions = {
  ENTERPRISE: 'Enterprise',
  SURGERY_CENTER: 'SurgeryCenter',
  SURGERY_CLINIC: 'SurgeryClinic',
  ON_CALL: 'OnCall'
}

export const solutionOptions = [
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'surgery-center', label: 'Surgery Center' },
  { value: 'surgery-clinic', label: 'Surgery Clinic' },
  { value: 'only-oncall', label: 'On Call Only' }
]

export const defaultSolutionOption = solutionOptions.find(
  solution => solution.value === 'enterprise'
).value

export const useSelectOrganization = () => {
  const canViewLinkedOrganizations = usePermissions(
    permissions.VIEW_LINKED_ORGANIZATIONS
  )
  const hasExternalAppointments = useFeature(
    featureTypes.ENABLE_EXTERNAL_APPOINTMENTS
  )

  const [selectedOrganization, setSelectedOrganization] = useFilterState(
    filterKeys.ORGANIZATION
  )

  const { data: { organizations = [] } = {} } = useQuery(
    ORGANIZATIONS_LIST_QUERY,
    {
      variables: {
        first: MAX_ORGANIZATIONS,
        skip: 0,
        sort: 'name',
        sortDirection: 'asc'
      },
      skip: !(hasExternalAppointments && canViewLinkedOrganizations)
    }
  )
  const organizationOptions = organizations.map(organization => ({
    label: organization.name,
    value: organization.id
  }))

  return [organizationOptions, selectedOrganization, setSelectedOrganization]
}

export default Organization
